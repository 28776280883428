import router from '../../router/router';
const axios = require('axios');
const pkceChallenge = require('pkce-challenge');
const querystring = require('querystring');
const crypto = require('crypto');

export default {
  namespaced: true,
  state: {
    test: 'test',

    client_id: 'b81f374e55364be4827755c5dd40b06e',
    response_type: 'code',
    redirect_uri: process.env.NODE_ENV === 'production' ? 'https://songguessr.marcrufeis.de/callback' :'https://songguesser.local:8080/callback',
    code_challenge_method: 'S256',
    scope: 'playlist-read-private user-modify-playback-state user-read-playback-state',
    grant_type: 'authorization_code',

    loggingIn: false,
    isLoggedIn: false,
    refreshing: false,
    authDetailsLoaded: false,
    errorOnTokens: false,
    loggedInUser: {},
    authDetails: {},
  },
  getters: {},
  mutations: {

    SET_LOGGED_IN(state, user) {
      state.loggedInUser = user;
      state.isLoggedIn = true;
      localStorage.setItem('loggedIn', Date.now());
    },
    SET_LOGGED_OUT(state) {
      state.loggedInUser = {};
      state.userDetails = {};
      state.authDetails = {};
      state.isLoggedIn = false;
      localStorage.removeItem('loggedIn');
      router.push({
        path: '/',
        query: { redirect: router.currentRoute.fullPath },
      });
    },
    SET_LOGGING_IN_STATUS(state, loggingIn) {
      state.loggingIn = loggingIn;
    },
    SET_REFRESHING_STATUS(state, refreshing) {
      console.log(refreshing);
      state.refreshing = refreshing;
    },
    SET_AUTH_DETAILS_FROM_API(state, details) {
      state.authDetailsLoaded = true;
      state.authDetails = details;
    },
    SET_TOKEN_EXPIRED() {
      this.commit('SET_LOGGED_OUT');
      this._vm.$bvToast.toast('please login again', {
        title: 'session expired',
        variant: 'danger',
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      });
    },
    SET_TOKEN_ERROR(state) {
      state.errorOnTokens = true;
      state.loggingIn = false;
    },

  },
  actions: {


    getAuthURL(context) {
      context.commit('SET_LOGGING_IN_STATUS', true);
      const { code_verifier, code_challenge } = pkceChallenge(128);
      const state = window.btoa(crypto.randomBytes(32).toString('base64'));
      console.log(code_verifier, code_verifier.length);
      console.log(code_challenge);
      localStorage.setItem('code_verifier', code_verifier);
      localStorage.setItem('state', state);
      const url = `https://accounts.spotify.com/authorize
?client_id=${context.state.client_id}
&response_type=${context.state.response_type}
&redirect_uri=${context.state.redirect_uri}
&code_challenge_method=${context.state.code_challenge_method}
&code_challenge=${code_challenge}
&state=${state}
&scope=${context.state.scope}`
      window.location = url;
      // context.dispatch('openAuthUrl', { path: url });
    },

    getTokens(context, code) {
      if (context.state.authDetails.access_token && router.currentRoute.path !== '/callback') return;
      context.commit('SET_LOGGING_IN_STATUS', true);
      console.log(localStorage.getItem('code_verifier'));
      axios.post('https://accounts.spotify.com/api/token', querystring.stringify({
        client_id: context.state.client_id,
        grant_type: context.state.grant_type,
        code,
        redirect_uri: context.state.redirect_uri,
        code_verifier: localStorage.getItem('code_verifier'),
      }), {
        // withCredentials: true,
      })
      .then(function (response) {
        console.log(response.data);
        context.commit('SET_AUTH_DETAILS_FROM_API', response.data);
        context.commit('SET_LOGGED_IN');
        context.commit('SET_LOGGING_IN_STATUS', false);
        localStorage.setItem('refresh', Date.now());
        localStorage.setItem('refreshToken', response.data.refresh_token);
        setTimeout(() => {
          context.dispatch('refreshTokens', true);
        }, response.data.expires_in * 1000 - 100);
        const redirect = router.currentRoute.query.redirect;
        if (redirect) return router.push(redirect);
        if (router.currentRoute.path === '/callback') router.push('/playlist');
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SET_TOKEN_ERROR');
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you in.', variant: 'danger' });
      });
    },

    refreshTokens(context, loggedIn = false) {
      if (context.state.isLoggedIn !== true) return;
      if (!loggedIn) context.commit('SET_LOGGING_IN_STATUS', true);
      if (loggedIn) context.commit('SET_REFRESHING_STATUS', true);
      axios.post('https://accounts.spotify.com/api/token', querystring.stringify({
        client_id: context.state.client_id,
        grant_type: 'refresh_token',
        refresh_token: context.state.authDetails.refresh_token,
      }), { withCredentials: true, })
      .then(function (response) {
        context.commit('SET_AUTH_DETAILS_FROM_API', response.data);
        context.commit('SET_LOGGED_IN');
        localStorage.setItem('refreshToken', response.data.refresh_token);
        if (!loggedIn) context.commit('SET_LOGGING_IN_STATUS', false);
        if (loggedIn) context.commit('SET_REFRESHING_STATUS', false);
        localStorage.setItem('loggedIn', Date.now());
        const redirect = router.currentRoute.query.redirect;
        if (redirect) router.push(redirect);
        setTimeout(() => context.dispatch('refreshTokens', true), response.data.expires_in * 1000 - 100);
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SET_LOGGING_IN_STATUS', false);
        context.commit('SET_REFRESHING_STATUS', false);
        if (context.state.isLoggedIn) context.commit('SET_TOKEN_EXPIRED');
        const redirect = router.currentRoute.query.redirect;
        if (redirect) context.commit('SHOW_TOAST', { title: 'Login required', text: 'Please login to get back where you wanted to go.', variant: 'danger' });
      });
    },

    logout(context) {
      axios.get('/auth/logout', { withCredentials: true, })
      .then(function (response) {
        if (response.data === true) {
          context.commit('SHOW_TOAST', { title: 'Sucess', text: 'You\'re now logged out.', variant: 'info' });
          context.commit('SET_LOGGED_OUT')
          context.dispatch('sendWandAgentStatus', 'logout');
          return;
        }
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you out.', variant: 'danger' });
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you out.', variant: 'danger' });
      });
    },

    openAuthUrl(context, options) {
      options.windowName = options.windowName ||  'ConnectWithOAuth'; // should not include space for IE
      options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=1000';
      options.callback = options.callback || function(){ window.location.reload(); };
      var that = this;
      // console.log(options.path);
      that._oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
      that._oauthInterval = window.setInterval(function(){
          if (that._oauthWindow.closed) {
              window.clearInterval(that._oauthInterval);
              options.callback();
          }
      }, 1000);
    },



  },
}
