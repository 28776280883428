
export default {
  namespaced: true,
  state: {
    test: 'test',

    playlistId: null,
    deviceId: null,
    
    players: [],

    gameType: null,

    iPadMode: false,
    fullscreen: false,

  },
  getters: {},
  mutations: {

    SET_DEVICE(state, id) {
      state.deviceId = id;
    },
    SET_PLAYLIST(state, id) {
      state.playlistId = id;
    },

    ADD_PLAYER(state, data) {
      state.players.push(data);
    },
    REMOVE_PLAYER(state) {
      state.players.pop();
    },
    REMOVE_PLAYER_INDEX(state, index) {
      state.players.splice(index, 1);
    },
    CHANGE_PLAYER(state, data) {
      state.players[data.i].name = data.name;
    },

    SET_GAME_TYPE(state, data) {
      state.gameType = data;
    },

    SET_IPAD_MODE(state, data) {
      state.iPadMode = data;
    },
    SET_FULLSCREEN(state, data) {
      state.fullscreen = data;
    },
    TOGGLE_FULLSCREEN(state) {
      state.fullscreen = !state.fullscreen;
    },

  },
  actions: {},
}
