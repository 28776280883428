<template>
  <div class="wrapper">
    <div class="bg"></div>
    <div class="home">
      <h1>songguessr</h1>
      <p>battle your friends in guessing songs</p>

      <div v-if="$store.state.auth.isLoggedIn" class="box">
        <p>you're logged in and ready to start</p>
        <b-button v-if="settingsValid" @click="$router.push('/game')">Play</b-button>
        <b-button @click="$router.push('/playlist')">{{ settingsValid ? 'Settings' : 'Setup Game' }}</b-button>

        <b-button @click="$store.commit('game/SET_IPAD_MODE', true)" v-if="!$store.state.game.iPadMode">Enable Tablet Mode</b-button>
        <b-button @click="$store.commit('game/SET_IPAD_MODE', false)" v-if="$store.state.game.iPadMode">Disable Tablet Mode</b-button>
      </div>

      <div v-if="!$store.state.auth.isLoggedIn" class="box">
        <p>to get started to need to login with spotify first</p>
        <b-button @click="$store.dispatch('auth/getAuthURL')">login with spotify</b-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },
  computed: {
    settingsValid() {
      return this.$store.state.game.playlistId
      && this.$store.state.game.deviceId
      && this.$store.state.game.players.length
      && this.$store.state.game.gameType;
    }
  }
}
</script>

<style scoped>

* {
  color: white;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  
  position: absolute;
  z-index: 0;

  background-color: black;
  background-image: url('~@/assets/bg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
}

.home {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
</style>