<template>
  <fullscreen :fullscreen.sync="$store.state.game.fullscreen" background="#fac78f">
    <router-view v-if="$store.state.auth.refreshing !== true" />
  </fullscreen>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    };
  },
  mounted() {
    if (localStorage.getItem('refreshToken') !== null) {
      console.log('true');
      this.$store.state.auth.isLoggedIn = true;
      this.$store.state.auth.authDetails.refresh_token = localStorage.getItem('refreshToken');
      this.$store.dispatch('auth/refreshTokens', true);
    }
  },
}
</script>

<style>
* {
  font-family: 'Open Sans', sans-serif;
}

.btn {
  border-radius: 0;
  margin: 0.2rem;
}
</style>
