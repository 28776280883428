import Vue from "vue"
import crypto from 'crypto'

const socket = new WebSocket("ws://localhost:8888/")

const emitter = new Vue({
  methods:{
    send(message){
      if (1 === socket.readyState)
        socket.send(message)
    }
  }
})
socket.onopen = () => {
  socket.send(`register-game:${crypto.randomBytes(2).toString('hex')}`)
}
socket.onmessage = function(msg){
  emitter.$emit("message", msg.data)
}
socket.onerror = function(err){
  emitter.$emit("error", err)
}


export default emitter