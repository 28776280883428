import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import spotifyOauth from './modules/spotify-oauth'
import gameStatus from './modules/game-status'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {

    SHOW_TOAST(state, options) {
      this._vm.$bvToast.toast(options.text, {
        title: options.title,
        variant: options.variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      });
    }

  },
  actions: {
  },
  modules: {
    auth: spotifyOauth,
    game: gameStatus,
  },
  plugins: [createPersistedState({
    paths: ['game']
  })],
})
