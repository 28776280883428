import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import axios from 'axios';
import VueAxios from 'vue-axios'
import Socket from "./socket"
import fullscreen from 'vue-fullscreen'
import 'joypad.js';

Vue.use(fullscreen)

Vue.prototype.$ws = Socket;

Vue.use(VueAxios, axios)
// axios.defaults.baseURL = 'https://glados.itmr-dev.de/'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
